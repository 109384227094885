import { gql } from 'urql'
import { IPlacement } from '../../placement'

export const createPlacementMutation = gql<
  {
    placementsCreate: {
      placement: IPlacement
    }
  },
  {
    applicantId: number
    jobStageId: number
    hiredDate: string
    onboardDate?: string
    endOfProbationDate?: string
    salary?: number
    typeOfSalary?: string
    currencyOfSalary: string
    fee?: number
    typeOfFee?: string
    revenue?: number
    currencyOfRevenue?: string
    profitSplits?: { userId: number; profitPercentage: number }[]
  }
>`
  mutation (
    $applicantId: Int!
    $jobStageId: Int!
    $hiredDate: ISO8601DateTime!
    $onboardDate: ISO8601DateTime
    $endOfProbationDate: ISO8601DateTime
    $salary: Float
    $typeOfSalary: PlacementTypeOfSalary
    $currencyOfSalary: PlacementCurrencyOfRevenue
    $fee: Float
    $typeOfFee: PlacementTypeOfFee
    $revenue: Float
    $currencyOfRevenue: PlacementCurrencyOfRevenue
    $profitSplits: [JSON!]
  ) {
    placementsCreate(
      input: {
        applicantId: $applicantId
        jobStageId: $jobStageId
        hiredDate: $hiredDate
        onboardDate: $onboardDate
        endOfProbationDate: $endOfProbationDate
        salary: $salary
        typeOfSalary: $typeOfSalary
        currencyOfSalary: $currencyOfSalary
        fee: $fee
        typeOfFee: $typeOfFee
        revenue: $revenue
        currencyOfRevenue: $currencyOfRevenue
        profitSplits: $profitSplits
      }
    ) {
      placement {
        id
        status
        hiredDate
        onboardDate
        endOfProbationDate
        salary
        typeOfSalary
        currencyOfSalary
        fee
        typeOfFee
        revenue
        currencyOfRevenue
        applicant {
          id
          jobStage {
            id
          }
        }
        profitSplits {
          id
          profitPercentage
          user {
            id
            fullName
            avatarVariants
            defaultColour
          }
        }
        company {
          id
          name
        }
      }
    }
  }
`
